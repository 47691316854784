<template>
  <div class="pa-3">
    <TasksTableComp :tasks="tasks"  />
  </div>
</template>

<script>
import TasksTableComp from '@/components/Manufactory/TasksTableComp.vue'

export default {
  name: 'MasterTasksMob',
  components: {
    TasksTableComp
  },
  data: () => ({}),
  computed: {
    tasks() {
      return this.$store.getters['manufactory/getTasksArchiveForMaster']
    }
  }
}
</script>